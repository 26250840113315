import React from 'react';
import { EmailOutlined } from "@mui/icons-material";
import { IconSquareBox } from "../Login/loginStyles";
import CustomDialog from "./CustomDialog";
import { Typography } from "@mui/material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import CustomButton from "./CustomButton";
import { useSelector } from '../../Redux/reduxHooks';

interface VerifyEmailSentDialogProps {
	open: boolean;
	onClose?: (isAddCustomer?: boolean, newCreatedCustomerId?: string) => void;
	isAddCustomer?: boolean;
}

const EmailSentDialog = ({ open, onClose, isAddCustomer }: VerifyEmailSentDialogProps) => {
	const colors = useSelector(selectColors);
	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox>
				<EmailOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				{
					isAddCustomer ? 'Account Created' : 'Email Sent'
				}
			</Typography>
			<Typography fontSize={16} textAlign="center" mb={10} color={colors.textMid}>
				{
					isAddCustomer ? "Customer has been added successfully" : "We've sent an email to complete registration"
				}


			</Typography>
			<Typography fontSize={12} mb={32} textAlign="center">
				{
					isAddCustomer ? null : 'Tip: Please check your spam folder in case the email was mistakenly filtered.'
				}
			</Typography>

			<CustomButton fullWidth onClick={() => onClose?.(isAddCustomer)} sx={{ mb: 10 }}>
				{
					isAddCustomer ? 'View Project' : "Close"
				}
			</CustomButton>
		</CustomDialog>
	);
};
export default EmailSentDialog;