import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../Components/Login/Login";
import { PrivateRoute } from "./PrivateRoutes";
import { PublicRoute } from "./PublicRoutes";
import AccountSettings from "../Components/AccountSettings/AccountSettings";
import { useEffect, useState } from "react";
import {
	isAdminLoggedIn,
	isManagerLoggedIn,
	isOfficeManagerLoggedIn,
	isRepresentativeLoggedIn,
	isTechnicianLoggedIn,
	isUserLoggedIn,
} from "../Services/userService";
import Loader from "../Components/Common/Loader";
import { toast } from "react-toastify";
import { getProfile } from "../Services/profileService";
import { useDispatch, useSelector } from "../Redux/reduxHooks";
import { allRoutes } from "./AllRoutes";
import Dashboard from "../Components/Dashboard/Dashboard";
import UpdateStatus from "../Components/UpdateStatus/UpdateStatus";
import OfficeManagerDetails from "../Components/OfficeManager/OfficeManagerDetails";
import EditOfficeManager from "../Components/OfficeManager/EditOfficeManager";
import AddOfficeManager from "../Components/OfficeManager/AddOfficeManager";
import ManagerDetails from "../Components/Manager/ManagerDetails";
import EditManager from "../Components/Manager/EditManager";
import AddManager from "../Components/Manager/AddManager";
import AddRepresentative from "../Components/Representative/AddRepresentative";
import RepresentativeDetails from "../Components/Representative/RepresentativeDetails";
import EditRepresentative from "../Components/Representative/EditRepresentative";
import AddInstallerAdmin from "../Components/InstallerAdmin/AddInstallerAdmin";
import InstallerAdminDetails from "../Components/InstallerAdmin/InstallerAdminDetails";
import EditInstallerAdmin from "../Components/InstallerAdmin/EditInstallerAdmin";
import { selectUser } from "../Redux/Slices/userSlice";
import InstallerAdmins from "../Components/InstallerAdmin/InstallerAdmins";
import OfficeManagers from "../Components/OfficeManager/OfficeManagers";
import Managers from "../Components/Manager/Managers";
import Representatives from "../Components/Representative/Representatives";
import Customers from "../Components/Customers/Customers";
import NotFound from "../Components/NotFound/NotFound";
import ResetPassword from "../Components/ResetPassword/ResetPassword";
import Logs from "../Components/Logs/Logs";
import RegisterTechnicianPage from "../Components/Technicians/RegisterTechnicianPage";
import AddTechnician from "../Components/Technicians/AddTechnician";
import EditTechnician from "../Components/Technicians/EditTechnician";
import TechnicianDetails from "../Components/Technicians/TechnicianDetails";
import Technicians from "../Components/Technicians/Technicians";
import EditCustomer from "../Components/Customers/EditCustomer";
import CustomerDetails from "../Components/Customers/CustomerDetails";
import AddCustomer from "../Components/Customers/AddCustomer";
import InstallerDashboard from "../Components/Common/InstallerDashboard";
import CustomerDocuments from "../Components/Customers/CustomerDocuments";
import TechnicianCalendarView from "../Components/Technicians/Calendar/TechnicianCalendarView";
import EditCompanyInformation from "../Components/CompanyInformation/EditCompanyInformation";
import AccountCreation from "../Components/Common/AccountCreation";
import SupportChat from "../Components/SupportChat/SupportChat";
import CsrVideoChat from "../Components/SupportChat/VideoChat";
import ColorTheme from "../Components/ColorTheme/ColorTheme";
import ChatWidget from "../Components/SupportChat/ChatWidget";
// import SignUp from "../Components/SignUp/SignUp";
// import Home from "../Components/Home/Home";
// import Estimate from "../Components/Estimate/Estimate";
// import WhyUs from "../Components/WhyUs/WhyUs";
// import SolarReport from "../Components/SolarReport/SolarReport";
// import ResetPassword from "../Components/ResetPassword/ResetPassword";
// import VerifyEmail from "../Components/VerifyEmail/VerifyEmail";
// import Scheduling from "../Components/Scheduling/Scheduling";
// import ProposalAcceptance from "../Components/Scheduling/SubPages/ProposalAcceptance";
// import SiteSurvey from "../Components/Scheduling/SubPages/SiteSurvey";
// import Payment from "../Components/Payment/Payment";
// import CADDesign from "../Components/Scheduling/SubPages/CADDesgin";

interface RouteWithComponent {
	path: string;
	Component: React.FC;
	isPrivate?: boolean;
	accessTo?: {
		admin?: boolean;
		officeManager?: boolean;
		manager?: boolean;
		representative?: boolean;
		technician?: boolean;
	};
}

const routesWithComponents = {
	HOME: { path: allRoutes.HOME, Component: Login },
	RESET_PASSWORD: { path: allRoutes.RESET_PASSWORD, Component: ResetPassword },
	// ESTIMATE: { path: allRoutes.ESTIMATE, Component: Estimate },
	// SOLAR_REPORT: { path: allRoutes.SOLAR_REPORT, Component: SolarReport },
	// LOGIN: { path: allRoutes.LOGIN, Component: Login },
	// SIGN_UP: { path: allRoutes.SIGN_UP, Component: SignUp },
	// VERIFY_EMAIL: { path: allRoutes.VERIFY_EMAIL, Component: VerifyEmail },
	// CAD_DESIGN: { path: allRoutes.CAD_DESIGN, Component: CADDesign, isPrivate: true },
	// SITE_SURVEY: { path: allRoutes.SITE_SURVEY, Component: SiteSurvey, isPrivate: true },
	// PROPOSAL_ACCEPTANCE: { path: allRoutes.PROPOSAL_ACCEPTANCE, Component: ProposalAcceptance, isPrivate: true }, // TODO: try to add these nested routes inside the route of Scheduling
	// SCHEDULING: { path: allRoutes.SCHEDULING, Component: Scheduling, isPrivate: true },
	// PAYMENT: { path: allRoutes.PAYMENT, Component: Payment, isPrivate: true },
	// WHY_US: { path: allRoutes.WHY_US, Component: WhyUs, isPrivate: true },
	DASHBOARD: { path: allRoutes.DASHBOARD, Component: Dashboard, isPrivate: true },
	UPDATE_STATUS: { path: allRoutes.UPDATE_STATUS, Component: UpdateStatus, isPrivate: true },
	LOGS: { path: allRoutes.LOGS, Component: Logs, isPrivate: true },
	ACCOUNT_SETTINGS: { path: allRoutes.ACCOUNT_SETTINGS, Component: AccountSettings, isPrivate: true },
	COMPANY_INFORMATION: {
		path: allRoutes.COMPANY_INFORMATION, Component: EditCompanyInformation, isPrivate: true, accessTo: {
			admin: true,
		},
	},


	INSTALLER_ADMINS: {
		path: allRoutes.INSTALLER_ADMINS,
		Component: InstallerAdmins,
		isPrivate: true,
		accessTo: {
			admin: true,
		},
	},
	ADD_INSTALLER_ADMIN: {
		path: allRoutes.ADD_INSTALLER_ADMIN,
		Component: AddInstallerAdmin,
		isPrivate: true,
		accessTo: {
			admin: true,
		},
	},
	VIEW_INSTALLER_ADMIN: {
		path: allRoutes.VIEW_INSTALLER_ADMIN,
		Component: InstallerAdminDetails,
		isPrivate: true,
		accessTo: {
			admin: true,
		},
	},
	EDIT_INSTALLER_ADMIN: {
		path: allRoutes.EDIT_INSTALLER_ADMIN,
		Component: EditInstallerAdmin,
		isPrivate: true,
		accessTo: {
			admin: true,
		},
	},

	OFFICE_MANAGERS: {
		path: allRoutes.OFFICE_MANAGERS,
		Component: OfficeManagers,
		isPrivate: true,
		accessTo: {
			admin: true,
			// officeManager: true,
		},
	},
	ADD_OFFICE_MANAGER: {
		path: allRoutes.ADD_OFFICE_MANAGER,
		Component: AddOfficeManager,
		isPrivate: true,
		accessTo: {
			admin: true,
			// officeManager: true,
		},
	},
	VIEW_OFFICE_MANAGER: {
		path: allRoutes.VIEW_OFFICE_MANAGER,
		Component: OfficeManagerDetails,
		isPrivate: true,
		accessTo: {
			admin: true,
			// officeManager: true,
		},
	},
	VIEW_OFFICE_MANAGER_DASHBOARD: {
		path: allRoutes.VIEW_OFFICE_MANAGER_DASHBOARD,
		Component: InstallerDashboard,
		isPrivate: true,
		accessTo: {
			admin: true,
		},
	},
	EDIT_OFFICE_MANAGER: {
		path: allRoutes.EDIT_OFFICE_MANAGER,
		Component: EditOfficeManager,
		isPrivate: true,
		accessTo: {
			admin: true,
			// officeManager: true,
		},
	},

	MANAGERS: {
		path: allRoutes.MANAGERS,
		Component: Managers,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			// manager: true,
		},
	},
	ADD_MANAGER: {
		path: allRoutes.ADD_MANAGER,
		Component: AddManager,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			// manager: true,
		},
	},
	VIEW_MANAGER: {
		path: allRoutes.VIEW_MANAGER,
		Component: ManagerDetails,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			// manager: true,
		},
	},
	VIEW_MANAGER_DASHBOARD: {
		path: allRoutes.VIEW_MANAGER_DASHBOARD,
		Component: InstallerDashboard,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
		},
	},
	EDIT_MANAGER: {
		path: allRoutes.EDIT_MANAGER,
		Component: EditManager,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			// manager: true,
		},
	},

	REPRESENTATIVES: {
		path: allRoutes.REPRESENTATIVES,
		Component: Representatives,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			// representative: true,
		},
	},

	ADD_REPRESENTATIVE: {
		path: allRoutes.ADD_REPRESENTATIVE,
		Component: AddRepresentative,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			// manager: true,
			// representative: true,
			// Commented Because of confusion 
		},
	},
	VIEW_REPRESENTATIVE: {
		path: allRoutes.VIEW_REPRESENTATIVE,
		Component: RepresentativeDetails,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			// representative: true,
		},
	},
	VIEW_REPRESENTATIVE_DASHBOARD: {
		path: allRoutes.VIEW_REPRESENTATIVE_DASHBOARD,
		Component: InstallerDashboard,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,

		},
	},
	EDIT_REPRESENTATIVE: {
		path: allRoutes.EDIT_REPRESENTATIVE,
		Component: EditRepresentative,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			// representative: true,
		},
	},
	CUSTOMERS: {
		path: allRoutes.CUSTOMERS,
		Component: Customers,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			representative: true,
			technician: true,
		},
	},
	VIEW_CUSTOMER: {
		path: allRoutes.VIEW_CUSTOMER,
		Component: CustomerDetails,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			representative: true,
			technician: true,
		},
	},
	VIEW_CUSTOMER_DOCUMENTS: {
		path: allRoutes.VIEW_CUSTOMER_DOCUMENTS,
		Component: CustomerDocuments,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			representative: true,
			technician: true,
		},
	},
	EDIT_CUSTOMER: {
		path: allRoutes.EDIT_CUSTOMER,
		Component: EditCustomer,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			representative: true,
		},
	},
	ADD_CUSTOMER: {
		path: allRoutes.ADD_CUSTOMER,
		Component: AddCustomer,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
			manager: true,
			representative: true
		},
	},
	TECHNICIANS: {
		path: allRoutes.TECHNICIANS,
		Component: Technicians,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
		},
	},
	VIEW_TECHNICIAN: {
		path: allRoutes.VIEW_TECHNICIAN,
		Component: TechnicianDetails,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
		},
	},

	ACCOUNT_CREATION: {
		path: allRoutes.ACCOUNT_CREATION,
		Component: AccountCreation,
	},
	EDIT_TECHNICIAN: {
		path: allRoutes.EDIT_TECHNICIAN,
		Component: EditTechnician,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
		},
	},
	ADD_TECHNICIAN: {
		path: allRoutes.ADD_TECHNICIAN,
		Component: AddTechnician,
		isPrivate: true,
		accessTo: {
			admin: true,
			officeManager: true,
		},
	},
	REGISTER_TECHNICIAN: {
		path: allRoutes.REGISTER_TECHNICIAN,
		Component: RegisterTechnicianPage,
		isPrivate: false,
	},
	CHAT: {
		path: allRoutes.CHAT,
		Component: SupportChat,
		isPrivate: true,
		accessTo: {
			admin: true,
		},

	},
	VIDEO_CHAT: {
		path: allRoutes.VIDEO_CHAT,
		Component: CsrVideoChat,
		isPrivate: true,
		accessTo: {
			admin: true
		},
	},
	CONFIGURATIONS: {
		path: allRoutes.CONFIGURATIONS,
		Component: ColorTheme,
		isPrivate: true,
		accessTo: {
			admin: true
		},
	},
	NOT_FOUND: {
		path: "*",
		Component: NotFound,
		isPrivate: true,
	},
};

// TODO: ** try to add these nested routes inside the route of Scheduling

const RouteNavigation = () => {
	const dispatch = useDispatch();
	// const isFirstRender = useRef(true);
	const user = useSelector(selectUser);
	const location = useLocation();

	const [loading, setLoading] = useState<boolean>(false);
	const [renderingRoutes, setRenderingRoutes] = useState<Array<RouteWithComponent>>([]);
	const hideChatWidgetOnPaths = ['/chat/video-chat']
	const shouldShowChatWidget = !hideChatWidgetOnPaths.includes(location.pathname);

	useEffect(() => {
		fetchUserProfile();

		// if (!isFirstRender.current) {
		// 	// check if this is not the initial render
		// 	fetchUserProfile();
		// } else {
		// 	isFirstRender.current = false; // set to false after the initial render
		// }
	}, []);

	useEffect(() => {
		const allRoutes = Object.values(routesWithComponents).filter((item: RouteWithComponent) =>
			item.accessTo
				? isAdminLoggedIn()
					? item.accessTo.admin
					: isOfficeManagerLoggedIn()
						? item.accessTo.officeManager
						: isTechnicianLoggedIn()
							? item.accessTo.technician
							: isManagerLoggedIn()
								? item.accessTo.manager
								: isRepresentativeLoggedIn() && item.accessTo.representative
				: item,
		);

		setRenderingRoutes(allRoutes);
	}, [user.id, isAdminLoggedIn(), isOfficeManagerLoggedIn(), isManagerLoggedIn(), isRepresentativeLoggedIn()]);

	const fetchUserProfile = async () => {
		if (isUserLoggedIn()) {
			setLoading(true);
			try {
				await dispatch(getProfile());
			} catch (error: any) {
				toast.error(error);
			}
			setLoading(false);
		}
	};

	return (
		<>
			<Loader open={loading} />
			{shouldShowChatWidget && isAdminLoggedIn() && <ChatWidget />}
			<Routes>
				{renderingRoutes.map((item: RouteWithComponent) => {
					const ComponentWrapper = item.isPrivate ? PrivateRoute : PublicRoute;

					return (
						<Route
							key={item.path}
							path={item.path}
							element={
								<ComponentWrapper>
									<item.Component />
								</ComponentWrapper>
							}
						/>
					);
				})}
			</Routes>
		</>
	);
};

export default RouteNavigation;
