import { useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomTextField from "../Common/CustomTextField";
import { Add, Download, SearchOutlined } from "@mui/icons-material";
import CustomTable from "../Common/Table/CustomTable";

import CustomButton from "../Common/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../../Redux/reduxHooks";
import { selectUser } from "../../Redux/Slices/userSlice";
import PlaceholderForEmptyTable from "../Common/Table/PlaceholderForEmptyTable";

import CustomDropdown from "../Common/CustomDropdown";
import { selectColors } from "../../Redux/Slices/generalSlice";
import DashboardData from "../Common/DashboardData";
import PageLayout from "../PageLayout/PageLayout";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import TechnicianCalendar from "../Technicians/Calendar/TechnicianCalendar";
import { isRepresentativeLoggedIn, isTechnicianLoggedIn } from "../../Services/userService";

export const tableHeaders = [
	{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
	{ text: "Name", key: "name" },
	{ text: "Email address", key: "email" },
];

export const TableBlock = ({
	heading,
	subHeading,
	addButtonText,
	addButtonPath,
	addButtonClick,
	emptyStateMessage,
	addButtonState,
	detailsPagePath,
	rowsPerPage,
	tableData,
	filterOptions,
	tableHeaders,
	isAssignedCustomers,
	isUnAssignedCustomers,
	onDownloadClick,
	onAddCustomer,
	isRep
}: {
	heading: string;
	subHeading: string;
	addButtonText?: string;
	addButtonPath?: string;
	addButtonClick?: () => void;
	emptyStateMessage?: string;
	addButtonState?: any;
	detailsPagePath?: string;
	rowsPerPage?: number;
	tableData?: Array<any>;
	tableHeaders: Array<any>;
	filterOptions?: any;
	isAssignedCustomers?: boolean
	isUnAssignedCustomers?: boolean
	onDownloadClick?: () => Promise<void>
	onAddCustomer?: () => void
	isRep?: boolean
}) => {
	const navigate = useNavigate();

	const [search, setSearch] = useState<string>("");

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	const handleFilterChange = (value: any) => {
		const selectedOption = filterOptions.options.find((option: any) => option.value.toString() === value.toString());
		filterOptions.setSelectedOption(selectedOption);
	};

	const searchedTableData = search
		? tableData?.filter(
			(item) =>
				item._id?.toString()?.includes(search?.toLowerCase()) ||
				item.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
				item.address?.toLowerCase()?.includes(search?.toLowerCase()) ||
				item.currentStep?.toLowerCase()?.includes(search?.toLowerCase()) ||
				item.status?.toLowerCase()?.includes(search?.toLowerCase()) ||
				item.log?.toLowerCase()?.includes(search?.toLowerCase()) ||
				item.timeStamp?.toLowerCase()?.includes(search?.toLowerCase()) ||
				item.email?.toLowerCase()?.includes(search?.toLowerCase()), // TODO: in future, change this logic to dynamic and add all tableHeaders here
		)
		: tableData;

	return (
		<>
			<Typography variant="h4" mb={8}>
				{heading} {tableData?.length ? `(${searchedTableData?.length})` : ""}
			</Typography>
			<Box
				display="flex"
				alignItems={{ xs: "stretch", md: "center" }}
				justifyContent="space-between"
				flexDirection={{ xs: "column", md: "row" }}
				gap={12}
				mb={32}
			>
				<Typography fontSize={16}>{subHeading}</Typography>
				<Box
					display="flex"
					alignItems={{ xs: "stretch", md: "center" }}
					justifyContent="flex-end"
					gap={12}
					flexDirection={{ xs: "column", md: "row" }}
				>
					{filterOptions && (
						<CustomDropdown
							options={filterOptions.options}
							value={filterOptions.selectedOption.value}
							onChange={(e: any) => handleFilterChange(e)}
							minWidth="220px"
							label={filterOptions.label || "Select Filter"}
						/>
					)}
					{!!tableData?.length && (
						<CustomTextField
							value={search}
							onChange={handleOnChange}
							placeholder="Search here"
							startIcon={<SearchOutlined sx={{ opacity: 0.7 }} />}
						/>
					)}
					{addButtonText && (
						<CustomButton
							sx={{ height: 40.13, minWidth: "max-content" }}
							startIcon={<Add />}
							onClick={() => addButtonClick?.() || navigate(addButtonPath || "", { state: addButtonState })}
						>
							{addButtonText}
						</CustomButton>
					)}

					{isRep && isRepresentativeLoggedIn() && (

						<span>
							<CustomButton
								sx={{ height: 40.13, minWidth: "max-content" }}
								onClick={onAddCustomer}
							>
								Add Customer
							</CustomButton>
						</span>
					)}
					{isAssignedCustomers && (
						<CustomButton
							sx={{ height: 40.13, minWidth: "max-content" }}
							startIcon={<Download />}
							onClick={onDownloadClick}
						>
							Download Customers Csv
						</CustomButton>
					)}
					{isUnAssignedCustomers && (
						<CustomButton
							sx={{ height: 40.13, minWidth: "max-content" }}
							startIcon={<Download />}
							onClick={onDownloadClick}
						>
							Download Customers Csv
						</CustomButton>
					)}
				</Box>
			</Box>

			{tableData?.length ? (
				<CustomTable
					headers={tableHeaders}
					rows={searchedTableData}
					detailsPagePath={detailsPagePath}
					rowsPerPage={rowsPerPage}
				/>
			) : (
				<PlaceholderForEmptyTable message={emptyStateMessage} />
			)}
		</>
	);
};

const Dashboard = () => {
	const user = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);

	const isTechnician = isTechnicianLoggedIn();

	const [loading, setLoading] = useState<boolean>(false);
	const colors = useSelector(selectColors);

	return (
		<PageLayout hideBackButton loading={loading}>
			<Typography variant="h2" mb={8}>
				{textSnippets["welcome_back_installer_dashboard_page"] || "Welcome back"}, {user.name ? `${user.name}!` : ""}
			</Typography>
			<Typography color={colors.textMid} mb={32}>
				{textSnippets[`check_your_stats_installer_dashboard_page`] || `Let's check your stats!`}
			</Typography>


			<DashboardData
				setLoading={setLoading}
				funnelName={textSnippets["all_customers_funnel_installer_dashboard_page"] || "All Customers Funnel"}
			/>
		</PageLayout>
	);
};

export default Dashboard;
