import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import { allRoutes } from "../../Routes/AllRoutes";
import { useNavigate } from "react-router-dom";
import {
	isAdminLoggedIn,
	isManagerLoggedIn,
	isOfficeManagerLoggedIn,
	isRepresentativeLoggedIn,
	isTechnicianLoggedIn,
} from "../../Services/userService";
import {
	getAllUnassignedCustomers,
	getCustomersOfRepresentative,
	getFunnelData,
	getRepresentativesOfManager,
} from "../../Services/installersService";
import { customerTableHeaders } from "../Representative/RepresentativeDetails";
import { useSelector } from "../../Redux/reduxHooks";
import { selectUser } from "../../Redux/Slices/userSlice";
import { getTechnicianCustomers } from "../../Services/dashboardService";
import CustomTableOptions from "../Common/CustomTableOptions";
import { TableBlock } from "../Dashboard/Dashboard";
import FunnelDiagram from "../Dashboard/FunnelDiagram";
import AssignRepresentativeDialog from "../Dashboard/AssignRepresentativeDialog";
import { roles } from "../../Utils/tokenKeyValue";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import CustomButton from "./CustomButton";
import { sendWelcomeEmailToCustomer } from "../../Services/welcomeEmailService";

export const tableHeaders = [
	{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
	{ text: "Name", key: "name" },
	{ text: "Email address", key: "email" },
];

const DashboardData = ({
	setLoading,
	funnelName,
	installerId,
	installerRole,
}: {
	setLoading: (bool: boolean) => void;
	funnelName: string;
	installerId?: string;
	installerRole?: string;
}) => {
	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);

	const isAdmin = isAdminLoggedIn();
	const isOfficeManager = isOfficeManagerLoggedIn() || installerRole === roles.OFFICE_MANAGER;
	const isManager = isManagerLoggedIn() || installerRole === roles.MANAGER;
	const isRepresentative = isRepresentativeLoggedIn() || installerRole === roles.REPRESENTATIVE;
	const isTechnician = isTechnicianLoggedIn();

	const [data, setData] = useState<{
		admins?: Array<any>;
		managers?: Array<any>;
		officeManagers?: Array<any>;
		customers?: Array<any>;
		representatives?: Array<any>;
	}>({
		admins: [],
		officeManagers: [],
		managers: [],
		customers: [],
		representatives: [],
	});
	const [assigningCustomer, setAssigningCustomer] = useState<{ text: string; id: string } | null>(null);
	const [funnelData, setFunnelData] = useState<any>(null);

	useEffect(() => {

		getAllUsers();

	}, [user.id]);

	const sendWelcomeEmail = (userId: string) => async () => {
		try {
			const payload = {
				userId
			};
			const welcomeEmail = await sendWelcomeEmailToCustomer(payload);
			toast.success(welcomeEmail?.data?.welcomeEmail?.message);
		} catch (error: any) {
			toast.error(error);
		}
	}

	const getAllUsers = async () => {
		if (!user.id) return;

		setLoading(true);
		try {
			const userIdForAPIcall = installerId || user.id || "";

			if (isAdmin && !installerRole) {
				const { data: allCustomers } = await getAllUnassignedCustomers();
				setData({ customers: allCustomers });
			} else if (isOfficeManager) {
				const { data: allCustomers } = await getAllUnassignedCustomers();
				setData({ customers: allCustomers });
			} else if (isManager) {
				const { data } = await getRepresentativesOfManager(userIdForAPIcall?.toString());
				setData({ representatives: data });
			} else if (isRepresentative) {
				const { data } = await getCustomersOfRepresentative(userIdForAPIcall?.toString());
				setData({ customers: data });
			} else if (isTechnician) {
				const { data } = await getTechnicianCustomers(userIdForAPIcall?.toString());
				setData({ customers: data });
			}

			if (!isTechnician) {
				const { data: apiFunnelData } = await getFunnelData(installerId);
				setFunnelData(apiFunnelData);
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const getRemainingCustomers = async () => {
		try {
			const { data: allCustomers } = await getAllUnassignedCustomers();
			setData({ ...data, customers: allCustomers });
		} catch (error: any) {
			toast.error(error);
		}
	};

	const openDialog = (props: { text: string; id: string }) => setAssigningCustomer(props);
	const closeDialog = () => setAssigningCustomer(null);

	const unassignedCustomersTableHeader = [
		...customerTableHeaders,
		{
			text: "Welcome Email",
			key: "welcomeEmail",
			sortable: true,
			showEllipses: true,
			maxWidth: 130,
			customComponent: (props: any) => (
				<CustomButton sx={{ width: "fit-content" }} variant="outlined" onClick={(event) => {
					event.stopPropagation();
					sendWelcomeEmail(props.id)();
				}}>
					Send Welcome Email
				</CustomButton>
			),
		},
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: { id: string; text: string }) => (
				<CustomTableOptions
					menuOptions={[
						{
							text: "Assign Representative",
							onClick: () => openDialog(props),
						},
					]}
				/>
			),
		},
	];

	const assignedCustomersTableHeaders = [
		...customerTableHeaders,
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: any) => (
				<CustomTableOptions
					menuOptions={[
						{
							text: "View Logs",
							onClick: () => {
								navigate(allRoutes.LOGS.replace(":id", props.sequentialId));
							},
						},

						{
							text: "Change Status",
							onClick: () => {
								navigate(allRoutes.UPDATE_STATUS.replace(":id", props.sequentialId));
							},
						},
					]}
				/>
			),
		},
	];

	return (
		<>
			{funnelData && !isTechnician && (
				<FunnelDiagram installerId={installerId} data={funnelData} funnelName={funnelName} />
			)}

			{/* {!isTechnician && ( */}
			{(isRepresentative || isTechnician) && (
				<TableBlock
					heading={"Assigned Customers"}
					subHeading={`These are all the ${"customers"} in this company:`}
					tableData={data.customers}
					tableHeaders={assignedCustomersTableHeaders}
					emptyStateMessage={"There are no assigned customers"}
				/>
			)}
			{/* )} */}

			{!isRepresentative && !isManager && !isTechnician && (
				<>
					<Divider sx={{ my: 16 }} />
					<TableBlock
						heading={textSnippets['unassigned_customers_installer_dashboard_page'] || "Unassigned Customers"}
						subHeading={textSnippets['customers_need_assignment_installer_dashboard_page'] || "These customers need to be assigned to a representative:"}
						tableData={data.customers}
						tableHeaders={unassignedCustomersTableHeader}
						emptyStateMessage={textSnippets['no_unassigned_customers_installer_dashboard_page'] || "There are no unassigned customers available"}
						detailsPagePath={allRoutes.VIEW_CUSTOMER}
					/>
				</>
			)}

			<AssignRepresentativeDialog
				open={!!assigningCustomer}
				activeUser={assigningCustomer}
				onClose={closeDialog}
				onSuccess={getRemainingCustomers}
			/>

			{/* <PDFDownloader /> */}
		</>
	);
};

export default DashboardData;
